import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { getAPI } from "../utils/Requests";

export default class PostPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      show: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    var url = `post/detail/${this.props.match.params.id}`;
    getAPI(url).then((data) => {
      this.setState({ posts: data });
    });
  };
  render() {
    return (
      <div className="content-wrap bg">
        <div className="top-text center">
          <Container>
            <Row>
              <Col>
                <h2>{this.state.posts.title}</h2>
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid>
          <Row className="img">
            <Image className="thumbnail" src={this.state.posts.image} />
          </Row>
        </Container>
        <div className="body-text">
          <Container>
            <Row>
              <Col>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.posts.description,
                  }}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
