import axios from "axios";

var baseURL = `https://apicb.tukangsablon.net/api/v1/front/`;

export const getAPI = (url) => {
    return axios
        .get(baseURL + url)
        .then((res) => {
            const data = res.data.data;
            return data;
        })
        .catch((err) => console.log(err));
};

export const postApi = (url, data, headers) => {
    return axios
        .post(baseURL + url, data, headers)
        .then((res) => {
            const data = res.data.data;
            return data;
        })
        .catch((err) => console.log(err));
};

export const putApi = (url, data, headers) => {
    return axios
        .put(baseURL + url, data, headers)
        .then((res) => {
            const data = res.data.data;
            return data;
        })
        .catch((err) => console.log(err));
};
