import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { getAPI } from "../utils/Requests";

export default class ActivityPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      show: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    var url = "post/list";
    getAPI(url).then((data) => {
      this.setState({ posts: data });
    });
  };

  render() {
    return (
      <div className="content-wrap bg">
        <div className="aktivitas-page">
          <Container>
            <Row>
              <Col>
                <h1 className="center">Aktivitas</h1>
                <div className="divider"></div>
                <h5 className="center">
                  Baca untuk mengetahui kegiatan Cahaya Baru 18 sehari - hari
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="activity">
          <Container>
            {this.state.posts.map((post) => (
              <div className="list-activity" key={post.id}>
                <Row>
                  <Col md={4} xs={12}>
                    <div className="thumbnail">
                      <Image className="img-responsive" src={post.image} />
                    </div>
                  </Col>
                  <Col md={8} xs={12}>
                    <div className="list-header">
                      <span>{post.created_at.substring(0, 10)}</span>
                    </div>
                    <div className="list-body">
                      <h5>
                        <a href={"/aktivitas/post/" + post.id}>{post.title}</a>
                      </h5>
                      <br />
                      <div
                        dangerouslySetInnerHTML={{ __html: post.description }}
                      />
                    </div>
                    <div className="list-footer">
                      <span>Cahaya Baru 18 Admin</span>
                      <br />
                      <a
                        className="read-link btn"
                        style={{ width: "150px", float: "right" }}
                        href={"aktivitas/post/" + post.id}
                      >
                        Baca
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </Container>
        </div>
      </div>
    );
  }
}
